<template>
  <div>
    <v-app id="inspire" :key="componentKey">
      <div style="margin-top: 20px; margin-left: 10px" class="slot_calendar">
        <!-- <v-btn
          class="mt-5 mr-2 justify-content-center"
          @click="customEventCreation"
          >button</v-btn
        >
        <div
          class="external-event"
          v-for="(item, i) in draggables"
          :key="i"
          draggable="true"
          @dragstart="onEventDragStart($event, item)"
          style="margin-top: 10px"
        >
          <strong>{{ item.title }}</strong>
          ({{ item.duration ? `${item.duration} min` : "no duration" }})
          <div>{{ item.content }}</div>
        </div> -->
        <v-row class="d-flex justify-space-between mb-5">
          <v-col>
            <v-autocomplete solo v-model="selected_ot" :items="all_ots" label="Select" :rules="validate_OT.select2"
              multiple dense outlined hint="Selected OTs" persistent-hint @change="change_ot_selection()" class="mr-4">
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete solo item-value="name" item-text="doctor_name" return-object required
              v-model="selected_surgeon_root" :items="surgeon_items" @change="selected_surgeon_root_change"
              label="Select Surgeon" outlined clearable dense>
              <template slot="selection" slot-scope="data">
                <!-- HTML that describe how select should render selected items -->
                {{ data.item.doctor_name }} - {{ data.item.name }}
              </template>
              <template slot="item" slot-scope="data">
                <!-- HTML that describe how select should render items when the select is open -->
                {{ data.item.doctor_name }} - {{ data.item.name }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" class="d-flex justify-end mr-2">
            <!-- :return-value.sync="time_range" -->
            <v-menu ref="manage_time_menu" v-model="manage_time_menu" :close-on-content-click="false" location="bottom"
              offset-y max-width="700px" min-width="700px">
              <template v-slot:activator="{ on }">
                <v-btn class="mr-4" v-on="on" title="Manage time range"><v-icon>mdi-calendar-clock</v-icon></v-btn>
              </template>
              <v-card class="pa-5">
                <v-range-slider :tick-labels="time_range_ticks" ticks="always" :min="0" :max="1440" :step="60"
                  class="mt-5" color="grey" track-color="grey" track-fill-color="#424242" thumb-color="grey"
                  v-model="time_range">
                </v-range-slider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <!-- <v-btn @click="$refs.manage_time_menu.save(time_range)"> -->
                  <!-- <v-btn @click="manage_time_menu = false"> Save </v-btn> -->
                </v-card-actions>
              </v-card>
            </v-menu>

            <v-menu ref="month_menu" v-model="month_menu" :close-on-content-click="false" location="bottom" offset-y>
              <!-- max-width="400px"
              min-width="400px"
              max-height="400px" -->
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" title="Go to month view">
                  <v-icon light>mdi-calendar-month</v-icon>
                </v-btn>
              </template>
              <v-card class="pa-5">
                <vue-cal :time="false" active-view="month" :clickToNavigate="true"
                  :disable-views="['years', 'week', 'day']" @cell-focus="
                                                                                                                              if (current_mini_view == 'month') {
                    selectedDate = $event;
                    month_menu = false;
                  }
                    " @view-change="current_mini_view = $event.view" style="max-width: 400px">
                </vue-cal>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <vue-cal ref="vuecal" :todayButton="true" :disable-views="disabledView" :selected-date="selectedDate"
              :events="events" :on-event-click="onEventClick" events-on-month-view="short" :editable-events="{
                  create: true,
                  drag: true,
                  resize: true,
                }" :snap-to-time="30" :drag-to-create-threshold="30" @event-drop="onEventDrop($event)"
              :split-days="splitDays" :sticky-split-labels="stickySplitLabels" :min-cell-width="minCellWidth"
              :min-split-width="minSplitWidth" :on-event-create="onEventCreate" @event-drag-create="
                  showEventCreationDialog = true;
                eventDragCreate('event-drag-create', $event);
                " @view-change="changeView('view-change', $event)" @event-duration-change="
      onEventDurationChange('event-duration-change', $event)
      " :time-from="time_range[0]" :time-to="time_range[1]" :time-step="timeStep">
              <!-- :special-hours="specialHours" -->
              <!-- <template #event="{ event }">
                <div class="vuecal__event-title" :class="[
                    event.status == 'Frozen' ? 'color-black' : '',
                    event.payment_status == 'Initiated' ? 'red' : '',
                  ]" v-html="event.title" />
              </template> -->
              <template #event="{ event }">
                <template v-if="event.background != true">
                  <div class="vuecal__event-title" :class="[
                      event.status == 'Frozen' ? 'color-yellow' : '',
                      event.payment_status == 'Initiated' ? 'red' : '',
                    ]">
                    <div>{{ event.surgeon_name }}</div>
                    <div>{{ event.title }}</div>
                  </div>
                </template>
                <template v-else>
                  <div class="vuecal__event-title">
                  </div>
                </template>
              </template>
            </vue-cal>
            <!--  #7ba4de
        :time-from="9 * 60"
          :time-to="19 * 60" -->
            <!-- :split-days="splitDays"
          :sticky-split-labels="stickySplitLabels"
          :min-cell-width="minCellWidth"
          :min-split-width="minSplitWidth" -->

            <!-- Using Vuetify (but we prefer Wave UI 🤘) -->
            <v-dialog v-model="showDialog" min-width="720">
              <v-card>
                <!-- <v-card-title>
                  <v-icon>{{ selectedEvent.icon }}</v-icon>
                  <span>{{ selectedEvent.title }}</span>
                  <v-spacer />
                  <strong>{{
                    selectedEvent.start &&
                    selectedEvent.start.format("DD/MM/YYYY")
                  }}</strong>
                </v-card-title>
                <v-card-text>
                  <p v-html="selectedEvent.contentFull" />
                  <strong>Event details:</strong>
                  <ul>
                    <li>
                      Event starts at:
                      {{
                        selectedEvent.start && selectedEvent.start.formatTime()
                      }}
                    </li>
                    <li>
                      Event ends at:
                      {{ selectedEvent.end && selectedEvent.end.formatTime() }}
                    </li>
                  </ul>
                </v-card-text> -->

                <!-- <v-card-title>
                  <v-row class="surgery_row">
                    <v-col cols="12" sm="12" class="pa-2">
                      <v-text-field
                        v-model="selectedEvent.title"
                        label="Slot Title"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>    
                  </v-row>
                </v-card-title> -->

                <v-card-text>
                  <v-row class="slot-modal blue_font_container">
                    <v-col cols="12" sm="12" class="pa-2">
                      <h5 class="font-weight-bold">
                        {{ selectedEvent.title }} <br />
                      </h5>
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">
                        Booking Date and Time <br />
                      </h5>
                      {{ formatDateTime(selectedEvent.booking_date_and_time) }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Status <br /></h5>
                      {{ selectedEvent.status }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Partner <br /></h5>
                      {{ selectedEvent.partner }}<br />
                      {{ selectedEvent.partner_name }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Surgeon <br /></h5>
                      {{ selectedEvent.surgeon }}<br />
                      {{ selectedEvent.surgeon_name }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Slot Duration <br /></h5>
                      {{
                          this.convertMinsToHrsMins(
                            selectedEvent.endTimeMinutes -
                            selectedEvent.startTimeMinutes
                          )
                      }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Slot Date <br /></h5>
                      {{
                          selectedEvent.start &&
                          selectedEvent.start.format("DD/MM/YYYY")
                      }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Slot Start Time <br /></h5>
                      {{
                          selectedEvent.start && selectedEvent.start.formatTime()
                      }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Slot End Time <br /></h5>
                      {{ selectedEvent.end && selectedEvent.end.formatTime() }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Net amount <br /></h5>
                      {{ selectedEvent.net_amount }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Paid amount <br /></h5>
                      {{ selectedEvent.paid_amount }}
                    </v-col>

                    <!-- <v-col cols="12" sm="6" class="pa-2">
                      <v-autocomplete solo  :items="procedure_items" label="Procedure" outlined dense></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <v-autocomplete solo  :items="laterlity_items" label="Laterlity" outlined dense></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2" style="margin-top: -15px">
                      <v-autocomplete solo  :items="patient_items" label="Patient" outlined dense></v-autocomplete>
                    </v-col> -->
                  </v-row>
                  <v-flex>
                    <!-- <v-btn class="ma-2" @click="showDialog = false">Save</v-btn> -->
                    <v-btn v-if="slotPaymentDetails.payment_required" :href="slotPaymentDetails.payment_url"
                      color="secondary" target="_blank" @click="showDialog = false">Continue Payment</v-btn>
                    <v-btn class="ma-2" @click="showDialog = false" outlined color="secondary">Cancel</v-btn>
                    <v-btn @click="discard_slot(selectedEvent.title)" outlined color="secondary">Discard Slot</v-btn>
                  </v-flex>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog v-model="showEventCreationDialog" :persistent="true" max-width="720">
              <v-card>
                <!-- <v-card-title>
                  <v-row class="surgery_row"> -->
                <!-- <v-col cols="12" sm="12" class="pa-2">
                     <v-switch
                      v-model="isSlot"
                      label="Slot"
                    />
                    </v-col> -->
                <!-- <v-col cols="12" sm="12" class="pa-2">
                      <v-text-field
                        v-model="selectedEvent.title"
                        label="Slot Title"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>    
                  </v-row>
                </v-card-title> -->
                <!-- <v-card-text v-if="!isSlot">
                  <v-row class="surgery-modal">
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">
                        Slot <br />
                      </h5>
                      {{ surgery.slot_name }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">
                        Surgeon <br />
                      </h5>
                      {{ surgery.surgeon }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Surgery Start Time <br /></h5>
                      {{ surgery.surgery_start_time }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Surgery End Time <br /></h5>
                      {{ surgery.surgery_end_time }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Surgery Date <br /></h5>
                      {{ surgery.surgery_date }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">OT <br /></h5>
                      {{ surgery.ot }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <v-autocomplete solo  v-model="surgery.procedure" :items="procedure_items" label="Procedure" outlined dense></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <v-autocomplete solo  v-model="surgery.laterlity" :items="laterlity_items" label="Laterlity" outlined dense></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2" style="margin-top: -15px">
                      <v-autocomplete solo  v-model="surgery.patient" :items="patient_items" label="Patient" outlined dense></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-flex>
                    <v-btn class="ma-2" @click="closeCreationDialog()">Save</v-btn>
                    <v-btn @click="cancelEventCreation()">Cancel</v-btn>
                  </v-flex>
                </v-card-text> -->

                <v-card-text v-if="isSlot">
                  <v-form ref="form" lazy-validation>
                    <v-row class="slot-modal">
                      <v-col cols="12" sm="6" class="pa-2">
                        <!-- <h5 class="font-weight-bold">Slot Date <br /></h5> -->
                        <!-- {{slot.slot_date}} -->
                        <v-text-field label="Slot Date" :value="slot.slot_date_formatted" outlined dense readonly
                          disabled></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" class="pa-2">
                        <!-- <h5 class="font-weight-bold">Slot Duration <br /></h5>
                        {{ slot.slot_duration }} -->
                        <v-autocomplete solo item-value="name" return-object required v-model="slot.slot_duration"
                          :items="duration_range" label="Select Slot Duration" outlined dense @change="add_time()">
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" class="pa-2">
                        <!-- <h5 class="font-weight-bold">Slot Start Time <br /></h5>
                        {{ slot.slot_start_time }} -->
                        <v-autocomplete solo item-value="name" return-object required v-model="slot.slot_start_time"
                          :items="start_time_range" label="Select Start Time" outlined dense @change="add_time()">
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="6" class="pa-2">
                        <!-- <h5 class="font-weight-bold">Slot End Time <br /></h5>
                        {{ slot.slot_end_time }} -->
                        <v-text-field label="Slot End Time" :value="slot.slot_end_time" outlined dense readonly
                          disabled></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" class="pa-2">
                        <!-- item-text="doctor_name" -->
                        <v-autocomplete solo item-value="name" item-text="doctor_name" return-object required
                          :rules="validate_surgeon.select1" v-model="selected_surgeon" :items="surgeon_items"
                          label="Select Surgeon" outlined dense>
                          <template slot="selection" slot-scope="data">
                            <!-- HTML that describe how select should render selected items -->
                            {{ data.item.doctor_name }} - {{ data.item.name }}
                          </template>
                          <template slot="item" slot-scope="data">
                            <!-- HTML that describe how select should render items when the select is open -->
                            {{ data.item.doctor_name }} - {{ data.item.name }}
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-flex>
                      <v-btn class="ma-2 secondary" @click="create_slot()">Save</v-btn>
                      <v-btn outlined color="secondary" @click="cancelEventCreation()">Cancel</v-btn>
                    </v-flex>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog v-model="slotPaymentDialog" :persistent="true" max-width="720">
              <v-card>
                <v-card-text>
                  <v-row class="slot-modal blue_font_container">
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">
                        {{ slotPaymentDetails.slot }} <br />
                      </h5>
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">OT <br /></h5>
                      {{ slotPaymentDetails.ot }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">
                        Booking Date and Time <br />
                      </h5>
                      {{
                          formatDateTime(slotPaymentDetails.booking_date_and_time)
                      }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Status <br /></h5>
                      {{ slotPaymentDetails.status }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Partner <br /></h5>
                      {{ slotPaymentDetails.partner }}<br />
                      {{ slotPaymentDetails.partner_name }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Surgeon <br /></h5>
                      {{ slotPaymentDetails.surgeon }}<br />
                      {{ slotPaymentDetails.surgeon_name }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Slot Duration <br /></h5>
                      {{ slotPaymentDetails.duration }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Slot Date <br /></h5>
                      {{ formatDate(slotPaymentDetails.slot_date) }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Slot Start Time <br /></h5>
                      {{ slotPaymentDetails.start_time }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Slot End Time <br /></h5>
                      {{ customFormatTime(slotPaymentDetails.end_time) }}
                    </v-col>
                  </v-row>
                  <v-flex>
                    <v-btn :href="slotPaymentDetails.payment_url" color="secondary" target="_blank"
                      @click="slotPaymentDialog = false">Continue Payment</v-btn>
                    <v-btn class="ma-2" @click="slotPaymentDialog = false" color="secondary" outlined>Skip</v-btn>
                  </v-flex>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </div>
    </v-app>
  </div>
</template>
  
<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import CommonApi from "@/services/CommonApi";
const Swal = require("sweetalert2");

const dailyHours = {
  from: 9 * 60,
  to: 18 * 60,
  class: "business-hours",
  split: 1,
};

export default {
  name: "SlotCalender",
  components: { VueCal },
  data: () => ({
    current_mini_view: "month",
    selectedDate: null,
    month_menu: false,
    manage_time_menu: false,
    time_range: [480, 1260],
    time_range_ticks: [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
    ],
    start_time_range: [
      "08:00:00",
      "08:10:00",
      "08:20:00",
      "08:30:00",
      "08:40:00",
      "08:50:00",
      "09:00:00",
      "09:10:00",
      "09:20:00",
      "09:30:00",
      "09:40:00",
      "09:50:00",
      "10:00:00",
      "10:10:00",
      "10:20:00",
      "10:30:00",
      "10:40:00",
      "10:50:00",
      "11:00:00",
      "11:10:00",
      "11:20:00",
      "11:30:00",
      "11:40:00",
      "11:50:00",
      "12:00:00",
      "12:10:00",
      "12:20:00",
      "12:30:00",
      "12:40:00",
      "12:50:00",
      "13:00:00",
      "13:10:00",
      "13:20:00",
      "13:30:00",
      "13:40:00",
      "13:50:00",
      "14:00:00",
      "14:10:00",
      "14:20:00",
      "14:30:00",
      "14:40:00",
      "14:50:00",
      "15:00:00",
      "15:10:00",
      "15:20:00",
      "15:30:00",
      "15:40:00",
      "15:50:00",
      "16:00:00",
      "16:10:00",
      "16:20:00",
      "16:30:00",
      "16:40:00",
      "16:50:00",
      "17:00:00",
      "17:10:00",
      "17:20:00",
      "17:30:00",
      "17:40:00",
      "17:50:00",
      "18:00:00",
      "18:10:00",
      "18:20:00",
      "18:30:00",
      "18:40:00",
      "18:50:00",
      "19:00:00",
      "19:10:00",
      "19:20:00",
      "19:30:00",
      "19:40:00",
      "19:50:00",
      "20:00:00",
      "20:10:00",
      "20:20:00",
      "20:30:00",
      "20:40:00",
      "20:50:00",
      "21:00:00",
    ],
    duration_range: [
      0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9,
      9.5, 10,
    ],
    procedure_items: [
      "Procedure 1",
      "Procedure 2",
      "Procedure 3",
      "Procedure 4",
    ],
    patient_items: ["Patient 1", "Patient 2", "Patient 3", "Patient 4"],
    laterlity_items: [
      "Laterlity 1",
      "Laterlity 2",
      "Laterlity 3",
      "Laterlity 4",
    ],
    timeStep: 60,
    timeFrom: 8 * 60,
    timeTo: 21 * 60,
    componentKey: 0,
    selectedEvent: {},
    showDialog: false,
    showEventCreationDialog: false,
    slotPaymentDialog: false,
    slotPaymentDetails: {},
    eventsCssClasses: ["leisure", "sport", "health"],
    events: [],
    draggables: [
      {
        // The id (or however you name it), will help you find which event to delete
        // from the callback triggered on drop into Vue Cal.
        id: 1,
        title: "Ext. Event 1",
        content: "content 1",
        duration: 60,
      },
      {
        id: 2,
        title: "Ext. Event 2",
        content: "content 2",
        duration: 30,
      },
      {
        id: 3,
        title: "Ext. Event 3",
        content: "content 3",
        // No defined duration here: will default to 2 hours.
      },
    ],
    stickySplitLabels: true,
    minCellWidth: 0,
    minSplitWidth: 0,
    // splitDays: [
    //   // The id property is added automatically if none (starting from 1), but you can set a custom one.
    //   // If you need to toggle the splits, you must set the id explicitly.
    //   { id: "OT 1", class: "ot-1", label: "OT 1", hide: false },
    //   { id: "OT 2", class: "ot-2", label: "OT 2", hide: false },
    //   { id: "Test OT", class: "test-ot", label: "Test OT", hide: false },
    // ],
    splitDays: [],

    specialHours: {
      1: dailyHours,
      2: dailyHours,
      3: [
        { from: 9 * 60, to: 12 * 60, class: "business-hours" },
        { from: 14 * 60, to: 18 * 60, class: "business-hours" },
      ],
      4: dailyHours,
      5: dailyHours,
    },
    isSlot: true,
    surgery: {
      slot_name: "",
      surgeon: JSON.parse(localStorage.getItem("userInfo")).user,
      surgery_start_time: "",
      surgery_end_time: "",
      surgery_date: "",
      ot: "",
      procedure: "",
      laterlity: "",
      patient: "",
    },
    slot: {
      slot_duration: "",
      slot_date: "",
      slot_date_formatted: "",
      slot_start_time: "",
      slot_end_time: "",
      split: "",
    },
    week_start_date: "",
    week_end_date: "",
    selected_ot: [],
    all_ots: [],
    validate_OT: {
      select2: [(v) => v.length > 0 || "OT is required"],
    },
    validate_surgeon: {
      select1: [(v) => Boolean(Object.keys(v || {})[0]) || "Field is required"],
    },
    isMobile: false,
    disabledView: [],
    surgeon_items: [],
    selected_surgeon: null,
    selected_surgeon_root: null,
  }),
  watch: {
    // whenever question changes, this function will run
    time_range(new_time_range) {
      localStorage.setItem("time_range", JSON.stringify(new_time_range));
    },
  },
  methods: {
    customFormatTime(date) {
      if (!date) return "";
      // const [date_string, time_string] = date.split(" ");
      return date.split(" ")[1];
    },
    formatDate(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    formatDateTime(date) {
      if (!date) return "";
      var date_string = date.split(" ")[0];
      const [year, month, day] = date_string.split("-");
      return `${day}/${month}/${year} ` + date.split(" ")[1];
    },
    async selected_surgeon_root_change(surgeon) {
      await this.get_slot_details(this.week_start_date, this.week_end_date);
      this.selected_surgeon = surgeon;
      localStorage.setItem("selected_surgeon", JSON.stringify(surgeon));
    },
    add_time() {
      var my_date = new Date();
      my_date.setHours(this.slot.slot_start_time.split(":")[0]);
      my_date.setMinutes(this.slot.slot_start_time.split(":")[1]);
      this.slot.slot_end_time = my_date
        .addMinutes(Math.floor(this.slot.slot_duration * 60))
        .formatTime();
      // this.slot.slot_end_time = new Date(this.slot.slot_start_time)
      //   .addHours(this.slot.slot_duration)
      //   .formatTime();
      // console.log(new_time);
      // return new_time;
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
      if (this.isMobile) {
        this.disabledView = ["month", "years", "year", "week"];
        this.$refs.vuecal.switchView("day");
      } else {
        this.disabledView = ["month", "years", "year"];
        this.$refs.vuecal.switchView("week");
      }
    },
    change_ot_selection() {
      this.splitDays.map((data) => {
        if (this.selected_ot.includes(data.label)) {
          data.hide = false;
        } else {
          data.hide = true;
        }
      });
      localStorage.setItem("selected_ot", JSON.stringify(this.selected_ot));
      if (this.week_start_date.length > 0) {
        this.get_slot_details(this.week_start_date, this.week_end_date);
      }
    },
    onEventCreate(event, deleteEventFunction) {
      // var start_time = new Date(event.start);
      // var current_time = new Date();
      if (this.selected_ot.length == 0) {
        this.$toast.error("Please Select One of OTs.", {
          position: "bottom-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        deleteEventFunction();
        return;
      }
      /* if (start_time < current_time) {
        this.$toast.error("Slot Date-Time can not be in the past.", {
          position: "bottom-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        deleteEventFunction();
        return;
        // this.events.pop();
      } else { */
      this.selectedEvent = event;
      this.deleteEventFunction = deleteEventFunction;

      return event;
      // }
    },
    cancelEventCreation() {
      this.closeCreationDialog();
      this.deleteEventFunction();
      this.reset_create_slot();
    },
    closeCreationDialog() {
      this.showEventCreationDialog = false;
      this.selectedEvent = {};
    },
    onEventClick(event, e) {
      // if (event.background) {
      //   this.timeFrom = this.getHours(event.start) * 60
      //   this.timeTo = this.getHours(event.end) * 60
      //   this.timeStep = 10
      //   this.events = []
      //   // if (event.class.includes("doctor")) {
      //   // this.forceRerender()
      //   this.$refs.vuecal.switchView("day");
      // } else
      // {
      if (!event.background) {
        this.selectedEvent = event;
        this.validate_payment(event.title, false);
        this.showDialog = true;
      }

      // Prevent navigating to narrower view (default vue-cal behavior).
      e.stopPropagation();
      // }
    },
    customEventCreation() {
      const dateTime = prompt(
        "Create event on (YYYY-MM-DD HH:mm)",
        "2022-11-22 16:00"
      );

      // Check if date format is correct before creating event.
      if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/.test(dateTime)) {
        this.$refs.vuecal.createEvent(
          // Formatted start date and time or JavaScript Date object.
          dateTime,
          // Event duration in minutes (Integer).
          120,
          // Custom event props (optional).
          { title: "New Event", content: "yay! 🎉", class: "blue-event" }
        );
      } else if (dateTime) alert("Wrong date format.");
    },
    onEventDragStart(e, draggable) {
      // Passing the event's data to Vue Cal through the DataTransfer object.
      e.dataTransfer.setData("event", JSON.stringify(draggable));
      e.dataTransfer.setData("cursor-grab-at", e.offsetY);
    },
    // The 3 parameters are destructured from the passed $event in @event-drop="onEventDrop".
    // `event` is the final event as Vue Cal understands it.
    // `originalEvent` is the event that was dragged into Vue Cal, it can come from the same
    //  Vue Cal instance, another one, or an external source.
    // `external` is a boolean that lets you know if the event is not coming from any Vue Cal.
    onEventDrop(event) {
      // uncomment below method to reset cal after drag and drop
      // this.forceRerender();
      // If the event is external, delete it from the data source on drop into Vue Cal.
      // If the event comes from another Vue Cal instance, it will be deleted automatically in there.
      // if (external) {
      //   const extEventToDeletePos = this.draggables.findIndex(
      //     (item) => item.id === originalEvent.id
      //   );
      //   if (extEventToDeletePos > -1)
      //     this.draggables.splice(extEventToDeletePos, 1);
      // }
      this.update_slot(event.originalEvent, event.newDate, event.newSplit);
    },
    load_data(json) {
      this.events = [];
      let events = CommonApi.get_json_data(json);
      this.events = events;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    getHours(str) {
      var date = new Date(str);
      var hours = ("0" + date.getHours()).slice(-2);
      return hours;
    },
    // getTime(str) {
    //   var date = new Date(str);
    //   var hours  = ("0" + date.getHours()).slice(-2);
    //   var minutes = ("0" + date.getMinutes()).slice(-2);
    //   return hours + ":" +minutes;
    // },
    convertDate(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    },
    convertMinsToHrsMins(mins) {
      let h = Math.floor(mins / 60);
      let m = mins % 60;
      h = h < 10 ? "0" + h : h; // (or alternatively) h = String(h).padStart(2, '0')
      m = m < 10 ? "0" + m : m; // (or alternatively) m = String(m).padStart(2, '0')
      return `${h}:${m}`;
    },
    convertToHrs(hours) {
      let hr = Number(hours.split(":")[0]);
      let min = hours.split(":")[1].replace("30", ".5");
      let str;
      if (min === "00") {
        str = hr;
      } else {
        str = hr + "" + min;
      }
      return Number(str);
    },
    changeView(e, event) {
      if (event.view === "week") {
        // this.timeFrom = 0 * 60,
        // this.timeTo = 24 * 60
        this.timeStep = 60;
        // this.load_data("slot_event.json");
        this.week_start_date = this.convertDate(event.startDate);
        this.week_end_date = this.convertDate(event.endDate);
        this.get_slot_details(
          this.convertDate(event.startDate),
          this.convertDate(event.endDate)
        );
      } else {
        this.get_slot_details(
          this.convertDate(event.startDate),
          this.convertDate(event.startDate)
        );
      }
    },
    eventDragCreate(e, event) {
      this.setSlotEvent(event);
      // this.setSurgeryEvent(event)
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetValidation();
      }
    },
    onEventDurationChange(e, event) {
      if (!event.event.background) {
        this.update_slot_duration(event.event);
      }
    },
    // setSurgeryEvent(event) {
    //    this.surgery.slot_name = event._eid
    //    this.surgery.surgery_start_time = event.start.formatTime()
    //    this.surgery.surgery_end_time = event.end.formatTime()
    //    this.surgery.surgery_date = event.start.format("DD/MM/YYYY")
    //    this.surgery.ot = this.splitDays.filter((data) => data.id === event.split).map(it => it.label).toString()
    // },
    setSlotEvent(event) {
      this.slot.slot_duration = this.convertToHrs(
        this.convertMinsToHrsMins(event.endTimeMinutes - event.startTimeMinutes)
      );
      this.slot.slot_date = event.start.format("YYYY-MM-DD");
      this.slot.slot_date_formatted = event.start.format("DD/MM/YYYY");
      this.slot.slot_start_time = event.start.formatTime() + ":00";
      this.slot.slot_end_time = event.end.formatTime();
      this.slot.split = event.split;
    },
    // Get Slot Details
    async get_slot_details(from_date, to_date) {
      let splitDays = [];
      this.splitDays.map((data) => {
        splitDays.push(data.id);
      });
      let data = {
        rooms: this.selected_ot,
        from_date: from_date,
        to_date: to_date,
        surgeon: this.selected_surgeon_root
          ? this.selected_surgeon_root.name
          : "",
      };
      // partner_id: "DOC011"
      await CommonApi.post_data("get_slot_details_0002", data).then((res) => {
        if (res.status == 200) {
          this.events = res.data;
        } else {
          this.$toast.error(res.message, {
            position: "bottom-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      });
    },
    // Get Create New Slot
    async create_slot() {
      this.$refs.form.validate();
      if (!this.$refs.form.validate()) {
        return;
      }
      // partner_id: "DOC011",
      let data = {
        ot: this.slot.split,
        surgeon: this.selected_surgeon.name,
        date: this.slot.slot_date,
        start_time: this.slot.slot_start_time,
        // duration: this.convertToHrs(this.slot.slot_duration),
        duration: this.slot.slot_duration,
      };
      await CommonApi.post_data("create_slot_0003", data).then((res) => {
        // console.log(res);
        if (res && res.status == 200) {
          // this.$toasted.show(res.message, {
          //   theme: "toasted-primary",
          //   position: "bottom-right",
          //   duration: 1500,
          // });
          this.validate_payment(res.data);
          this.$toast.success(res.message, {
            position: "bottom-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.get_slot_details(this.week_start_date, this.week_end_date);
        } else {
          // this.$toasted.show(res.message, {
          //   theme: "toasted-primary",
          //   position: "bottom-right",
          //   duration: 1500,
          // });
          this.$toast.error(res.message, {
            position: "bottom-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.cancelEventCreation();
        }
        this.selected_surgeon = null;
        this.closeCreationDialog();
      });
    },
    async validate_payment(id, dialog = true) {
      this.slotPaymentDetails = {};
      let data = {
        slot_id: id,
      };
      await CommonApi.post_data("validate_payment_0026", data).then((res) => {
        if (res && res.status == 200) {
          if (res.data.payment_required) {
            if (dialog) this.slotPaymentDialog = true;
            this.slotPaymentDetails = res.data;
          }
        }
      });
    },
    reset_create_slot() {
      this.selected_surgeon = {};
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetValidation();
      }
    },
    // Get Update Slot
    async update_slot(original_event, new_date, new_split) {
      let data = {
        slot: original_event.title,
        room: new_split,
        date: this.convertDate(new_date),
        start_time: new_date.formatTime() + ":00",
      };
      await CommonApi.post_data("update_slot_0004", data).then((res) => {
        if (res && res.status == 200) {
          // this.$toasted.show(res.message, {
          //   theme: "toasted-primary",
          //   position: "bottom-right",
          //   duration: 1500,
          // });
          this.$toast.success(res.message, {
            position: "bottom-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        } else {
          // this.$toasted.show(res.message, {
          //   theme: "toasted-primary",
          //   position: "bottom-right",
          //   duration: 1500,
          // });
          this.$toast.error(res.message, {
            position: "bottom-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.get_slot_details(this.week_start_date, this.week_end_date);
        }
      });
    },
    // Get Update Slot Duration
    async update_slot_duration(new_event) {
      let data = {
        slot: new_event.title,
        duration: this.convertToHrs(
          this.convertMinsToHrsMins(
            new_event.endTimeMinutes - new_event.startTimeMinutes
          )
        ),
      };
      await CommonApi.post_data("update_slot_duration_0005", data).then(
        (res) => {
          if (res && res.status == 200) {
            // this.$toasted.show(res.message, {
            //   theme: "toasted-primary",
            //   position: "bottom-right",
            //   duration: 1500,
            // });
            this.validate_payment(data.slot);
            this.$toast.success(res.message, {
              position: "bottom-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          } else {
            // this.$toasted.show(res.message, {
            //   theme: "toasted-primary",
            //   position: "bottom-right",
            //   duration: 1500,
            // });
            this.$toast.error(res.message, {
              position: "bottom-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            this.get_slot_details(this.week_start_date, this.week_end_date);
          }
        }
      );
    },
    // Get Get Surgeon list
    async get_surgeon_list() {
      await CommonApi.post_data("get_surgeon_list_0009").then((res) => {
        if (res.data.length && res.status == 200) {
          this.surgeon_items = res.data;
        }
      });
    },
    // Get Get Surgeon list
    async get_ot_list() {
      await CommonApi.post_data("get_ot_list_0025").then((res) => {
        if (res.data.length && res.status == 200) {
          this.splitDays = res.data;
        }
      });
    },
    // Discard Slot
    async discard_slot(slot) {
      Swal.fire({
        title: "Discard Slot",
        text: `Are you really want to Discard Slot ${slot} ?`,
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        cancelButtonColor: "#d33",
      }).then((result) => {
        if (result.value) {
          let data = {
            slot: slot,
          };
          CommonApi.post_data("discard_slot_0006", data).then((res) => {
            if (res && res.status == 200) {
              this.$toast.success(res.message, {
                position: "bottom-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
              this.get_slot_details(this.week_start_date, this.week_end_date);
              this.showDialog = false;
            } else {
              this.$toast.error(res.message, {
                position: "bottom-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }
          });
        }
      });
    },
  },
  async mounted() {
    await this.get_ot_list();
    this.onResize();
    this.time_range = localStorage.getItem("time_range")
      ? JSON.parse(localStorage.getItem("time_range"))
      : [480, 1260];
    this.selected_surgeon_root = localStorage.getItem("selected_surgeon")
      ? JSON.parse(localStorage.getItem("selected_surgeon"))
      : "";
    window.addEventListener("resize", this.onResize, { passive: true });
    this.all_ots = this.splitDays.map((data) => data.label);
    this.selected_ot = localStorage.getItem("selected_ot")
      ? JSON.parse(localStorage.getItem("selected_ot"))
      : [];
    this.change_ot_selection();
    // this.load_data("slot_event.json");
    const today = new Date();
    // Get the first day of the current week (Monday)
    const firstDay = new Date(
      today.setDate(
        today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)
      )
    );
    // Get the last day of the current week (Sunday)
    const lastDay = new Date(
      today.setDate(today.getDate() - today.getDay() + 7)
    );
    this.week_start_date = firstDay.toISOString().split("T")[0];
    this.week_end_date = lastDay.toISOString().split("T")[0];

    await this.get_slot_details(
      firstDay.toISOString().split("T")[0],
      lastDay.toISOString().split("T")[0]
    );
    this.get_surgeon_list();
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  // get_surgeon_label(surgeon) {
  //   return `${surgeon.doctor_name} - ${surgeon.name}`;
  // },
};
// {
//   start: "2022-11-19 12:00",
//   end: "2022-11-19 14:00",
//   title: "LUNCH",
//   class: "lunch",
//   background: true,
//   split: 1,
// },
// {
//   start: "2022-11-20 12:00",
//   end: "2022-11-20 14:00",
//   title: "LUNCH",
//   class: "lunch",
//   background: true,
//   split: 2,
// },
// {
//   start: "2022-11-21 12:00",
//   end: "2022-11-21 14:00",
//   title: "LUNCH",
//   class: "lunch",
//   background: true,
//   split: 3,
// },
// {
//   start: "2022-11-22 12:00",
//   end: "2022-11-22 14:00",
//   title: "LUNCH",
//   class: "lunch",
//   background: true,
//   split: 1,
// },
// {
//   start: "2022-11-23 12:00",
//   end: "2022-11-23 14:00",
//   title: "LUNCH",
//   class: "lunch",
//   background: true,
//   split: 1,
// },
// {
//   start: "2022-11-19 10:00",
//   end: "2022-11-19 11:30",
//   title: "appointment",
//   content: '<i class="icon material-icons">local_hospital</i>',
//   class: "health",
//   split: 1, // Has to match the id of the split you have set (or integers if none).
// },
// {
//   start: "2022-11-19 18:00",
//   end: "2022-11-19 20:00",
//   title: "Dentist appointment",
//   content: '<i class="icon material-icons">local_hospital</i>',
//   class: "health",
//   split: 2,
// },
// {
//   start: "2022-11-20 18:30",
//   end: "2022-11-20 20:30",
//   title: "Crossfit",
//   content: '<i class="icon material-icons">fitness_center</i>',
//   class: "sport",
//   split: 1,
// },
// {
//   start: "2022-11-14 10:30",
//   end: "2022-11-14 12:30",
//   title: "General Surgery",
//   content: '<i class="icon material-icons">fitness_center</i>',
//   split: 1,
// },
// {
//   start: "2022-11-14 8:00",
//   end: "2022-11-14 10:00",
//   title: "Head & Neck Surgery",
//   content: '<i class="icon material-icons">fitness_center</i>',
//   split: 2,
// },
// {
//   start: "2022-11-14 01:00 PM",
//   end: "2022-11-14 02:00 PM",
//   title: "General Surgery",
//   content: '<i class="icon material-icons">fitness_center</i>',
//   split: 3,
// },
// {
//   start: "2022-11-14 04:00 PM",
//   end: "2022-11-14 06:00 PM",
//   title: "Head & Neck Surgery",
//   content: '<i class="icon material-icons">fitness_center</i>',
//   split: 3,
// },
// {
//   start: "2022-11-15 1:00 PM",
//   end: "2022-11-15 03:00 PM",
//   title: "General Surgery",
//   content: '<i class="icon material-icons">fitness_center</i>',
//   split: 3,
// },
// {
//   start: "2022-11-15 10:00 AM",
//   end: "2022-11-15 12:00 PM",
//   title: "Head & Neck Surgery",
//   content: '<i class="icon material-icons">fitness_center</i>',
//   split: 1,
// },
// {
//   start: "2022-11-15 3:00 PM",
//   end: "2022-11-15 05:00 PM",
//   title: "Surgery disabled",
//   content: '<i class="icon material-icons">fitness_center</i>',
//   split: 2,
//   deletable: false,
//   resizable: false,
//   draggable: false,
// },
// {
//   start: "2022-11-17 1:00 PM",
//   end: "2022-11-17 03:00 PM",
//   title: "General Surgery",
//   content: '<i class="icon material-icons">fitness_center</i>',
//   split: 3,
// },
// {
//   start: "2022-11-17 10:00 AM",
//   end: "2022-11-17 12:00 PM",
//   title: "Head & Neck Surgery",
//   content: '<i class="icon material-icons">fitness_center</i>',
//   split: 1,
// },
// {
//   start: "2022-11-17 3:00 PM",
//   end: "2022-11-17 05:00 PM",
//   title: "Surgery disabled",
//   content: '<i class="icon material-icons">fitness_center</i>',
//   split: 2,
//   deletable: false,
//   resizable: false,
//   draggable: false,
// },
// {
//   start: "2022-11-16 03:30",
//   end: "2022-11-16 05:30",
//   title: "Physiotherapy",
//   content: '<i class="icon material-icons">physiotherapy_center</i>',
//   class: "Physiotherapy",
//   split: 3,
// },
// {
//   start: "2022-11-16 06:30",
//   end: "2022-11-16 08:30",
//   title: "Physiotherapy",
//   content: '<i class="icon material-icons">physiotherapy_center</i>',
//   class: "General Surgery",
//   split: 3,
// },
// {
//   start: "2022-11-16 09:30",
//   end: "2022-11-16 10:30",
//   title: "Checkup",
//   content: '<i class="icon material-icons">physiotherapy_center</i>',
//   class: "General Surgery",
//   split: 1,
// },
// {
//   start: "2022-11-17 09:30 AM",
//   end: "2022-11-17 11:30 AM",
//   title: "Physiotherapy",
//   content: '<i class="icon material-icons">physiotherapy_center</i>',
//   class: "Physiotherapy",
//   split: 3,
// },
// {
//   start: "2022-11-17 06:30",
//   end: "2022-11-17 08:30",
//   title: "Physiotherapy",
//   content: '<i class="icon material-icons">physiotherapy_center</i>',
//   class: "General Surgery",
//   split: 3,
// },
// {
//   start: "2022-11-17 08:30",
//   end: "2022-11-17 09:30",
//   title: "Checkup",
//   content: '<i class="icon material-icons">physiotherapy_center</i>',
//   class: "General Surgery",
//   split: 2,
// },
// {
//   start: "2022-11-18 09:30",
//   end: "2022-11-18 10:30",
//   title: "Checkup",
//   content: '<i class="icon material-icons">physiotherapy_center</i>',
//   class: "General Surgery",
//   split: 1,
// },
// {
//   start: "2022-11-18 02:30 PM",
//   end: "2022-11-18 03:30 PM",
//   title: "Physiotherapy",
//   content: '<i class="icon material-icons">physiotherapy_center</i>',
//   class: "Physiotherapy",
//   split: 3,
// },
// {
//   start: "2022-11-18 06:30",
//   end: "2022-11-18 08:30",
//   title: "Physiotherapy",
//   content: '<i class="icon material-icons">physiotherapy_center</i>',
//   class: "General Surgery",
//   split: 3,
// },
// {
//   start: "2022-11-18 12:30 PM",
//   end: "2022-11-18 01:30 PM",
//   title: "Checkup",
//   content: '<i class="icon material-icons">physiotherapy_center</i>',
//   class: "General Surgery",
//   split: 2,
// },
// {
//   start: "2022-11-23 02:30",
//   end: "2022-11-23 03:30",
//   title: "surgery",
//   content: '<i class="icon material-icons">surgery_center</i>',
//   class: "surgery",
//   split: 3,
// },
</script>
  
<style scoped lang="scss">
// General styles.
* {
  font-size: 12px;
  margin: 0;
  padding: 0;
}

html,
body,
#app {
  height: 100%;
}

#app {
  padding: 40px;
  box-sizing: border-box;
}

// a {
//   position: fixed;
//   top: 5px;
//   right: 5px;
//   color: #333;
// }
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}
</style>

