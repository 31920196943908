<template>
  <div>
    <v-app id="inspire" :key="componentKey">
      <div style="margin-top: 20px; margin-left: 10px" class="slot_calendar">
        <!-- <v-row class="d-flex justify-space-between">
          <v-col cols="12" sm="3" class="mb-5">
            <v-autocomplete solo
              v-model="selected_ot"
              :items="all_ots"
              label="Select"
              :rules="rules.select2"
              multiple
              chips
              hint="Selected OTs"
              persistent-hint
              @change="change_ot_selection()"
            >
            </v-autocomplete>
          </v-col>
        </v-row> -->
        <v-row class="d-flex justify-space-between mb-5">
          <v-col class="d-flex justify-start mr-2">
            <v-autocomplete solo v-model="selected_ot" :items="all_ots" label="Select" :rules="rules.select2" multiple
              outlined dense hint="Selected OTs" persistent-hint @change="change_ot_selection()" class="mr-4">
            </v-autocomplete>

            <v-autocomplete solo item-value="name" item-text="doctor_name" return-object required
              v-model="selected_surgeon_root" :items="surgeon_items" @change="selected_surgeon_root_change"
              label="Select Surgeon" outlined clearable dense class="mr-4">
              <template slot="selection" slot-scope="data">
                <!-- HTML that describe how select should render selected items -->
                {{ data.item.doctor_name }} - {{ data.item.name }}
              </template>
              <template slot="item" slot-scope="data">
                <!-- HTML that describe how select should render items when the select is open -->
                {{ data.item.doctor_name }} - {{ data.item.name }}
              </template>
            </v-autocomplete>

            <v-autocomplete solo v-model="selected_procedure_root" required @change="selected_procedure_root_change()"
              :items="procedure_items" item-text="name" item-value="name" return-object label="Procedure" outlined
              clearable dense></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" class="d-flex justify-end mr-2">
            <!-- :return-value.sync="time_range" -->
            <v-menu ref="manage_time_menu" v-model="manage_time_menu" :close-on-content-click="false" location="bottom"
              offset-y max-width="700px" min-width="700px">
              <template v-slot:activator="{ on }">
                <v-btn class="mr-4" v-on="on" title="Manage time range"><v-icon>mdi-calendar-clock</v-icon></v-btn>
              </template>
              <v-card class="pa-5">
                <v-range-slider :tick-labels="time_range_ticks" ticks="always" :min="0" :max="1440" :step="60"
                  class="mt-5" color="grey" track-color="grey" track-fill-color="#424242" thumb-color="grey"
                  v-model="time_range">
                </v-range-slider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <!-- <v-btn @click="$refs.manage_time_menu.save(time_range)"> -->
                  <!-- <v-btn @click="manage_time_menu = false"> Save </v-btn> -->
                </v-card-actions>
              </v-card>
            </v-menu>

            <v-menu ref="month_menu" v-model="month_menu" :close-on-content-click="false" location="bottom" offset-y>
              <!-- max-width="400px"
              min-width="400px"
              max-height="400px" -->
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" title="Go to month view">
                  <v-icon light>mdi-calendar-month</v-icon>
                </v-btn>
              </template>
              <v-card class="pa-5">
                <vue-cal :time="false" active-view="month" :clickToNavigate="true"
                  :disable-views="['years', 'week', 'day']" @cell-focus="
                                                        if (current_mini_view == 'month') {
                    selectedDate = $event;
                    month_menu = false;
                  }
                    " @view-change="current_mini_view = $event.view" style="max-width: 400px">
                </vue-cal>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <vue-cal ref="vuecal" :todayButton="true" :selected-date="selectedDate" :disable-views="disabledView"
              :events="events" :on-event-click="onEventClick" events-on-month-view="short" :editable-events="{
                  create: true,
                  drag: true,
                  resize: true,
                }" :snap-to-time="10" :drag-to-create-threshold="10" @event-drop="onEventDrop" :split-days="splitDays"
              :sticky-split-labels="stickySplitLabels" :min-cell-width="minCellWidth" :min-split-width="minSplitWidth"
              :on-event-create="onEventCreate" @event-drag-create="eventDragCreate('event-drag-create', $event)"
              @view-change="changeView('view-change', $event)" :time-from="timeFrom" :time-to="timeTo"
              :time-step="timeStep">
              <template #event="{ event }">
                <div class="vuecal__event-title" v-html="event.title" />
                <small v-if="dayview">
                  <strong v-if="event.patient_name">Patient:</strong>
                  <span>{{ event.patient_name }}</span><br />
                  <strong v-if="event.procedure">Procedure:</strong>
                  <span>{{ event.procedure }}</span>
                </small>
              </template>
            </vue-cal>

            <v-dialog v-model="showDialog" width="756">
              <v-card>
                <v-card-title class="pl-8 blue_font_bold">
                  Surgery
                </v-card-title>

                <v-card-text>
                  <v-row class="surgery-modal blue_font_container">
                    <v-col cols="12" sm="3" class="pa-2">
                      <h5 class="font-weight-bold">ID <br /></h5>
                      {{ selectedEvent.title }}
                    </v-col>
                    <v-col cols="12" sm="3" class="pa-2">
                      <h5 class="font-weight-bold">Slot <br /></h5>
                      {{ selectedEvent.slot && selectedEvent.slot }}
                    </v-col>
                    <v-col cols="12" sm="3" class="pa-2">
                      <h5 class="font-weight-bold">Slot Date <br /></h5>
                      {{
                          selectedEvent.start &&
                          selectedEvent.start.format("DD/MM/YYYY")
                      }}
                    </v-col>
                    <v-col cols="12" sm="3" class="pa-2">
                      <h5 class="font-weight-bold">Slot Start Time <br /></h5>
                      {{
                          selectedEvent.slot_start_time &&
                          selectedEvent.slot_start_time
                      }}
                    </v-col>
                    <v-col cols="12" sm="3" class="pa-2">
                      <h5 class="font-weight-bold">Slot End Time <br /></h5>
                      {{
                          selectedEvent.slot_end_time &&
                          selectedEvent.slot_end_time
                      }}
                    </v-col>
                    <v-col cols="12" sm="3" class="pa-2">
                      <h5 class="font-weight-bold">Surgery Date <br /></h5>
                      {{ selectedEvent.date && formatDate(selectedEvent.date) }}
                    </v-col>
                    <v-col cols="12" sm="3" class="pa-2">
                      <h5 class="font-weight-bold">
                        Surgery Start Time <br />
                      </h5>
                      {{
                          selectedEvent.start &&
                          selectedEvent.start.formatTime() + ":00"
                      }}
                    </v-col>
                    <v-col cols="12" sm="3" class="pa-2">
                      <h5 class="font-weight-bold">Surgery End Time <br /></h5>
                      {{
                          selectedEvent.end &&
                          selectedEvent.end.formatTime() + ":00"
                      }}
                    </v-col>
                    <v-col cols="12" sm="3" class="pa-2">
                      <h5 class="font-weight-bold">OT <br /></h5>
                      {{ selectedEvent.split }}
                    </v-col>
                    <v-col cols="12" sm="3" class="pa-2">
                      <h5 class="font-weight-bold">Surgery Duration <br /></h5>
                      {{
                          selectedEvent.procedure_time &&
                          selectedEvent.procedure_time
                      }}
                    </v-col>
                    <v-col cols="12" sm="3" class="pa-2">
                      <h5 class="font-weight-bold">Anaesthesia Type <br /></h5>
                      {{
                          selectedEvent.anaesthesia_type &&
                          selectedEvent.anaesthesia_type
                      }}
                    </v-col>
                    <v-col cols="12" sm="3" class="pa-2">
                      <h5 class="font-weight-bold">Laterlity <br /></h5>
                      {{ selectedEvent.laterlity }}
                    </v-col>
                    <v-col cols="12" sm="3" class="pa-2">
                      <h5 class="font-weight-bold">Procedure <br /></h5>
                      {{ selectedEvent.procedure && selectedEvent.procedure }}
                    </v-col>
                    <v-col cols="12" sm="3" class="pa-2">
                      <h5 class="font-weight-bold">Surgeon <br /></h5>
                      {{ selectedEvent.surgeon }}<br />
                      {{ selectedEvent.surgeon_name }}
                    </v-col>
                    <v-col cols="12" sm="3" class="pa-2">
                      <h5 class="font-weight-bold">Patient <br /></h5>
                      {{ selectedEvent.patient }}<br />
                      {{ selectedEvent.patient_name }}
                    </v-col>
                    <v-col cols="12" sm="3" class="pa-2">
                      <h5 class="font-weight-bold">Partner <br /></h5>
                      {{ selectedEvent.partner }}<br />
                      {{ selectedEvent.partner_name }}
                    </v-col>

                    <v-col cols="12" sm="12" class="pa-2">
                      <v-divider :thickness="10"></v-divider>
                    </v-col>
                    <!-- <v-col cols="12" sm="4" class="pa-2">
                      <v-autocomplete solo :items="patient_items" item-text="patients_name" item-value="name" return-object
                        label="Patient" outlined dense></v-autocomplete>
                    </v-col> -->
                    <!-- <v-col cols="12" sm="4" class="pa-2">
                      <v-autocomplete solo :items="procedure_items" item-text="name" item-value="name" return-object
                        label="Procedure" outlined dense></v-autocomplete>
                    </v-col> -->
                    <!-- here surgon dropdown not needed because we are now showing read only surgon from slot details -->
                    <!-- 
                      <v-col cols="12" sm="5" class="pa-2">
                      <v-autocomplete solo
                        v-model="selectedEvent.surgeon"
                        :items="surgeon_items"
                        item-text="doctor_name"
                        item-value="name"
                        return-object
                        label="Surgeon"
                        outlined
                        dense
                        readonly
                        disabled
                      >
                        <template slot="selection" slot-scope="data">
                          {{ data.item.doctor_name }} - {{ data.item.name }}
                        </template>
                        <template slot="item" slot-scope="data">
                          {{ data.item.doctor_name }} - {{ data.item.name }}
                        </template>
                      </v-autocomplete>
                    </v-col> -->
                    <v-col cols="12" sm="12" class="pa-2" style="margin-top: -15px">
                      <v-textarea v-model="selectedEvent.surgeon_special_request" name="Special Request"
                        label="Special Request" type="text" placeholder="Enter Special Request" outlined
                        dense></v-textarea>
                    </v-col>
                  </v-row>
                  <v-flex>
                    <v-btn v-if="surgeryPaymentDetails.payment_required" :href="surgeryPaymentDetails.payment_url"
                      color="secondary" target="_blank" @click="showDialog = false">Continue Payment</v-btn>
                    <v-btn class="ma-2" @click="showDialog = false" outlined color="secondary">Cancel</v-btn>
                    <v-btn @click="discard_surgery(selectedEvent.title)" color="secondary" outlined>Discard</v-btn>
                  </v-flex>
                </v-card-text>
              </v-card>
            </v-dialog>

            <v-dialog v-model="showEventCreationDialog" :persistent="true" width="626">
              <v-card>
                <v-card-title class="pl-8 blue_font_bold">
                  New Surgery
                </v-card-title>
                <v-card-text>
                  <v-form ref="form" lazy-validation>
                    <v-row class="surgery-modal blue_font_container">
                      <v-col cols="12" sm="12" class="pa-2">
                        <h5 class="font-weight-bold">Slot <br /></h5>
                        {{ surgery.slot_name }}
                      </v-col>
                      <v-col cols="12" sm="4" class="pa-2">
                        <h5 class="font-weight-bold">Slot Date <br /></h5>
                        {{ formatDate(surgery.slot_date) }}
                      </v-col>
                      <v-col cols="12" sm="4" class="pa-2">
                        <h5 class="font-weight-bold">Slot Start Time <br /></h5>
                        {{ surgery.slot_start_time + ":00" }}
                      </v-col>
                      <v-col cols="12" sm="4" class="pa-2">
                        <h5 class="font-weight-bold">Slot End Time <br /></h5>
                        {{ surgery.slot_end_time + ":00" }}
                      </v-col>
                      <v-col cols="12" sm="4" class="pa-2">
                        <h5 class="font-weight-bold">Surgery Date <br /></h5>
                        {{ formatDate(surgery.surgery_date) }}
                      </v-col>
                      <v-col cols="12" sm="4" class="pa-2">
                        <h5 class="font-weight-bold">
                          Surgery Start Time <br />
                        </h5>
                        {{ surgery.surgery_start_time + ":00" }}
                      </v-col>
                      <v-col cols="12" sm="4" class="pa-2">
                        <h5 class="font-weight-bold">
                          Surgery End Time <br />
                        </h5>
                        {{ surgery.surgery_end_time }}
                      </v-col>
                      <v-col cols="12" sm="4" class="pa-2">
                        <h5 class="font-weight-bold">OT <br /></h5>
                        {{ surgery.ot }}
                      </v-col>
                      <v-col cols="12" sm="4" class="pa-2">
                        <h5 class="font-weight-bold">
                          Surgery Duration <br />
                        </h5>
                        {{ surgery.surgery_duration }}
                      </v-col>
                      <v-col cols="12" sm="4" class="pa-2">
                        <h5 class="font-weight-bold">Surgeon <br /></h5>
                        {{ surgery.surgeon }}<br />
                        {{ surgery.surgeon_name }}
                      </v-col>
                      <v-col cols="12" sm="12" class="pa-2">
                        <v-divider :thickness="10"></v-divider>
                      </v-col>

                      <v-col cols="12" sm="4" class="pa-2">
                        <v-autocomplete solo v-model="surgery.patient" required :rules="rules.select1"
                          :items="patient_items" item-text="patients_name" item-value="name" return-object label="Patient"
                          outlined dense></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="4" class="pa-2">
                        <v-autocomplete solo v-model="surgery.procedure" required :rules="rules.select1"
                          @change="get_procedure_details()" :items="procedure_items" item-text="name" item-value="name"
                          return-object label="Procedure" outlined dense></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="4" class="pa-2">
                        <v-autocomplete solo v-model="surgery.laterlity" required :rules="rules.select1"
                          :items="laterlity_items" label="Laterlity" outlined dense></v-autocomplete>
                      </v-col>
                      <!-- <v-col cols="12" sm="4" class="pa-2">
                        <v-autocomplete solo
                          v-model="surgery.surgeon"
                          required
                          :rules="rules.select1"
                          @change="get_procedure_details()"
                          :items="surgeon_items"
                          item-text="doctor_name"
                          item-value="name"
                          return-object
                          label="Surgeon"
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col> -->
                      <v-col cols="12" sm="12" class="pa-2" style="margin-top: -15px">
                        <v-textarea v-model="surgery.special_request" name="Special Request" label="Special Request"
                          type="text" placeholder="Enter Special Request" outlined dense></v-textarea>
                      </v-col>
                    </v-row>
                    <v-flex>
                      <v-btn class="ma-2 secondary" @click="create_surgery()">Save</v-btn>
                      <v-btn outlined color="secondary" @click="cancelEventCreation()">Cancel</v-btn>
                    </v-flex>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog v-model="surgeryPaymentDialog" :persistent="true" max-width="720">
              <v-card>
                <v-card-text>
                  <v-row class="slot-modal blue_font_container">
                    <v-col cols="12" sm="12" class="pa-2">
                      <h5 class="font-weight-bold">
                        {{ surgeryPaymentDetails.surgery }} <br />
                      </h5>
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Slot <br /></h5>

                      {{ surgeryPaymentDetails.slot }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">OT <br /></h5>
                      {{ surgeryPaymentDetails.ot }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Status <br /></h5>
                      {{ surgeryPaymentDetails.status }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Surgeon <br /></h5>
                      {{ surgeryPaymentDetails.surgeon }}<br />
                      {{ surgeryPaymentDetails.surgeon_name }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Slot Duration <br /></h5>
                      {{ surgeryPaymentDetails.duration }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Slot Date <br /></h5>
                      {{ formatDate(surgeryPaymentDetails.slot_date) }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Slot Start Time <br /></h5>
                      {{ surgeryPaymentDetails.start_time }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <h5 class="font-weight-bold">Slot End Time <br /></h5>
                      {{ customFormatTime(surgeryPaymentDetails.end_time) }}
                    </v-col>
                  </v-row>
                  <v-flex>
                    <!-- <v-btn class="ma-2" @click="showDialog = false">Save</v-btn> -->
                    <v-btn :href="surgeryPaymentDetails.payment_url" color="secondary" target="_blank"
                      @click="surgeryPaymentDialog = false">Continue Payment</v-btn>
                    <v-btn class="ma-2" @click="surgeryPaymentDialog = false" outlined color="secondary">Skip</v-btn>
                  </v-flex>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </div>
    </v-app>
  </div>
</template>
  
<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import CommonApi from "@/services/CommonApi";
const Swal = require("sweetalert2");

const dailyHours = {
  from: 9 * 60,
  to: 18 * 60,
  class: "business-hours",
  split: 1,
};

export default {
  name: "SurgeryCalender",
  components: { VueCal },
  data: () => ({
    current_mini_view: "month",
    laterlity_items: ["Left", "Right", "Bilateral", "N/A"],
    selectedDate: null,
    month_menu: false,
    manage_time_menu: false,
    time_range: [480, 1260],
    time_range_ticks: [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
    ],
    start_time_range: [
      "08:00:00",
      "08:10:00",
      "08:20:00",
      "08:30:00",
      "08:40:00",
      "08:50:00",
      "09:00:00",
      "09:10:00",
      "09:20:00",
      "09:30:00",
      "09:40:00",
      "09:50:00",
      "10:00:00",
      "10:10:00",
      "10:20:00",
      "10:30:00",
      "10:40:00",
      "10:50:00",
      "11:00:00",
      "11:10:00",
      "11:20:00",
      "11:30:00",
      "11:40:00",
      "11:50:00",
      "12:00:00",
      "12:10:00",
      "12:20:00",
      "12:30:00",
      "12:40:00",
      "12:50:00",
      "13:00:00",
      "13:10:00",
      "13:20:00",
      "13:30:00",
      "13:40:00",
      "13:50:00",
      "14:00:00",
      "14:10:00",
      "14:20:00",
      "14:30:00",
      "14:40:00",
      "14:50:00",
      "15:00:00",
      "15:10:00",
      "15:20:00",
      "15:30:00",
      "15:40:00",
      "15:50:00",
      "16:00:00",
      "16:10:00",
      "16:20:00",
      "16:30:00",
      "16:40:00",
      "16:50:00",
      "17:00:00",
      "17:10:00",
      "17:20:00",
      "17:30:00",
      "17:40:00",
      "17:50:00",
      "18:00:00",
      "18:10:00",
      "18:20:00",
      "18:30:00",
      "18:40:00",
      "18:50:00",
      "19:00:00",
      "19:10:00",
      "19:20:00",
      "19:30:00",
      "19:40:00",
      "19:50:00",
      "20:00:00",
      "20:10:00",
      "20:20:00",
      "20:30:00",
      "20:40:00",
      "20:50:00",
      "21:00:00",
    ],
    surgeryPaymentDetails: {},
    surgeryPaymentDialog: false,
    procedure_items: [],
    patient_items: [],
    // surgeon_items: [],
    timeStep: 60,
    timeFrom: 8 * 60,
    timeTo: 21 * 60,
    componentKey: 0,
    selectedEvent: {},
    showDialog: false,
    showEventCreationDialog: false,
    eventsCssClasses: ["leisure", "sport", "health"],
    events: [],
    draggables: [
      {
        // The id (or however you name it), will help you find which event to delete
        // from the callback triggered on drop into Vue Cal.
        id: 1,
        title: "Ext. Event 1",
        content: "content 1",
        duration: 60,
      },
      {
        id: 2,
        title: "Ext. Event 2",
        content: "content 2",
        duration: 30,
      },
      {
        id: 3,
        title: "Ext. Event 3",
        content: "content 3",
        // No defined duration here: will default to 2 hours.
      },
    ],
    stickySplitLabels: true,
    minCellWidth: 0,
    minSplitWidth: 0,
    splitDays: [],
    // splitDays: [
    //   // The id property is added automatically if none (starting from 1), but you can set a custom one.
    //   // If you need to toggle the splits, you must set the id explicitly.
    //   { id: "OT 1", class: "ot-1", label: "OT 1", hide: false },
    //   { id: "OT 2", class: "ot-2", label: "OT 2", hide: false },
    //   { id: "Test OT", class: "test-ot", label: "Test OT", hide: false },
    // ],

    specialHours: {
      1: dailyHours,
      2: dailyHours,
      3: [
        { from: 9 * 60, to: 12 * 60, class: "business-hours" },
        { from: 14 * 60, to: 18 * 60, class: "business-hours" },
      ],
      4: dailyHours,
      5: dailyHours,
    },
    surgery: {
      slot_name: "",
      surgery_start_time: "",
      surgery_end_time: "-",
      surgery_date: "",
      surgery_duration: "-",
      ot: "",
      // surgeon: {},
      procedure: {},
      patient: {},
      slot_date: "",
      special_request: "",
      slot_start_time: "",
      slot_end_time: "",
      surgeon: "",
      surgeon_name: "",
      laterlity: "",
    },
    slot: {
      slot_duration: "",
      slot_date: "",
      slot_start_time: "",
      slot_end_time: "",
    },
    week_start_date: "",
    week_end_date: "",
    selected_ot: [],
    all_ots: [],
    rules: {
      select1: [(v) => Boolean(Object.keys(v || {})[0]) || "Field is required"],
      select2: [(v) => v.length > 0 || "OT is required"],
    },
    dayview: false,
    isMobile: false,
    disabledView: [],
    initial_events: [],
    surgeon_items: [],
    selected_surgeon_root: null,
    selected_procedure_root: null,
  }),
  watch: {
    // whenever question changes, this function will run
    time_range(new_time_range) {
      if (this.dayview == false) {
        this.timeFrom = new_time_range[0];
        this.timeTo = new_time_range[1];
      }
      localStorage.setItem("time_range", JSON.stringify(new_time_range));
    },
  },
  methods: {
    customFormatTime(date) {
      if (!date) return "";
      // const [date_string, time_string] = date.split(" ");
      return date.split(" ")[1];
    },
    formatDate(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    async selected_surgeon_root_change(value) {
      localStorage.setItem("selected_surgeon", JSON.stringify(value));
      if (this.dayview == true) {
        this.get_partner_surgeries(this.week_start_date, this.week_end_date);
      } else {
        this.get_surgery_details(this.week_start_date, this.week_end_date);
      }
      this.get_procedure_list(
        this.selected_surgeon_root ? this.selected_surgeon_root.name : ""
      );
    },
    async selected_procedure_root_change() {
      if (this.dayview == true) {
        this.get_partner_surgeries(this.week_start_date, this.week_end_date);
      } else {
        this.get_surgery_details(this.week_start_date, this.week_end_date);
      }
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
      if (this.isMobile) {
        this.disabledView = ["month", "years", "year", "week"];
        this.$refs.vuecal.switchView("day");
      } else {
        this.disabledView = ["month", "years", "year"];
        this.$refs.vuecal.switchView("week");
      }
    },
    change_ot_selection() {
      this.splitDays.map((data) => {
        if (this.selected_ot.includes(data.label)) {
          data.hide = false;
        } else {
          data.hide = true;
        }
      });
      localStorage.setItem("selected_ot", JSON.stringify(this.selected_ot));
      if (this.week_start_date.length > 0) {
        if (this.dayview == true) {
          this.get_partner_surgeries(this.week_start_date, this.week_end_date);
        } else {
          this.get_surgery_details(this.week_start_date, this.week_end_date);
        }
      }
    },
    onEventCreate(event, deleteEventFunction) {
      if (!this.$refs.vuecal.isWeekView) {
        this.reset_sugery_slot();
        this.selectedEvent = event;
        this.deleteEventFunction = deleteEventFunction;
        return event;
      } else {
        this.$toast.error("Surgery creation is allowed only through Slot.", {
          position: "bottom-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        deleteEventFunction();
      }
    },
    cancelEventCreation() {
      this.closeCreationDialog();
      this.deleteEventFunction();
    },
    closeCreationDialog() {
      this.showEventCreationDialog = false;
      this.selectedEvent = {};
    },
    onEventClick(event, e) {
      if (event.background) {
        this.timeFrom = this.getTimeStamp(event.start);
        this.timeTo = this.getTimeStamp(event.end);
        this.timeStep = 10;
        this.surgery.slot_name = event.title;
        this.surgery.slot_date = event.start.format("YYYY-MM-DD");
        this.surgery.slot_start_time = event.start.formatTime();
        this.surgery.slot_end_time = event.end.formatTime();
        this.surgery.surgeon = event.surgeon;
        this.surgery.surgeon_name = event.surgeon_name;
        this.selected_ot = [];
        this.splitDays.map((data) => {
          if (data.id !== event.split) {
            data.hide = true;
          } else {
            data.hide = false;
            this.selected_ot.push(data.label);
          }
        });
        this.get_partner_surgeries(this.week_start_date, this.week_end_date);
        this.get_procedure_list(event.surgeon);
        this.$refs.vuecal.switchView("day");
      } else {
        this.validate_payment(event.slot, false);
        this.selectedEvent = event;
        this.showDialog = true;

        // Prevent navigating to narrower view (default vue-cal behavior).
        e.stopPropagation();
      }
    },
    customEventCreation() {
      const dateTime = prompt(
        "Create event on (YYYY-MM-DD HH:mm)",
        "2022-11-22 16:00"
      );

      // Check if date format is correct before creating event.
      if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/.test(dateTime)) {
        this.$refs.vuecal.createEvent(
          // Formatted start date and time or JavaScript Date object.
          dateTime,
          // Event duration in minutes (Integer).
          120,
          // Custom event props (optional).
          { title: "New Event", content: "yay! 🎉", class: "blue-event" }
        );
      } else if (dateTime) alert("Wrong date format.");
    },
    onEventDragStart(e, draggable) {
      // Passing the event's data to Vue Cal through the DataTransfer object.
      e.dataTransfer.setData("event", JSON.stringify(draggable));
      e.dataTransfer.setData("cursor-grab-at", e.offsetY);
    },
    // The 3 parameters are destructured from the passed $event in @event-drop="onEventDrop".
    // `event` is the final event as Vue Cal understands it.
    // `originalEvent` is the event that was dragged into Vue Cal, it can come from the same
    //  Vue Cal instance, another one, or an external source.
    // `external` is a boolean that lets you know if the event is not coming from any Vue Cal.
    onEventDrop(e) {
      // console.log(e);

      // uncomment below method to reset cal after drag and drop
      // this.forceRerender();
      // If the event is external, delete it from the data source on drop into Vue Cal.
      // If the event comes from another Vue Cal instance, it will be deleted automatically in there.
      // if (external) {
      //   const extEventToDeletePos = this.draggables.findIndex(
      //     (item) => item.id === originalEvent.id
      //   );
      //   if (extEventToDeletePos > -1)
      //     this.draggables.splice(extEventToDeletePos, 1);
      // }
      if (this.dayview == true) {
        this.get_details_for_surgery_movement(
          this.surgery.slot_name,
          e.event.title,
          this.getTime(e.event.start)
        );
        return;
      }

      let drop_slots = this.events.filter(
        (data) =>
          data.split === e.newSplit &&
          data.background &&
          data.start.includes(this.convertDate(e.event.start))
      );
      var slot_matched = false;
      drop_slots.map(async (data) => {
        if (
          this.checkDatesFallsBetween(
            this.convertDate(e.event.start) + " " + this.getTime(e.event.start),
            this.convertDate(e.event.end) + " " + this.getTime(e.event.end),
            this.convertDate(data.start) + " " + this.getTime(data.start),
            this.convertDate(data.end) + " " + this.getTime(data.end)
          )
        ) {
          let slot = data.title;
          if (slot) {
            slot_matched = true;
            await this.get_details_for_surgery_movement(
              slot,
              e.event.title,
              this.getTime(e.event.start)
            );
            return;
          }
        }
      });
      if (slot_matched == false) {
        this.get_surgery_details(this.week_start_date, this.week_end_date);
        this.$toast.error("Surgery movement allowed between slots only.", {
          position: "bottom-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
    convertH2M(timeInHour) {
      var timeParts = timeInHour.split(":");
      return Number(timeParts[0]) * 60 + Number(timeParts[1]);
    },
    getTimeStamp(str) {
      var date = new Date(str);
      var hours = ("0" + date.getHours()).slice(-2);
      var minutes = ("0" + date.getMinutes()).slice(-2);
      return this.convertH2M(hours + ":" + minutes);
    },
    getHours(str) {
      var date = new Date(str);
      var hours = ("0" + date.getHours()).slice(-2);
      return hours;
    },
    getTime(str) {
      var date = new Date(str);
      var hours = ("0" + date.getHours()).slice(-2);
      var minutes = ("0" + date.getMinutes()).slice(-2);
      return hours + ":" + minutes;
    },
    convertDate(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    },
    convertMinsToHrsMins(mins) {
      let h = Math.floor(mins / 60);
      let m = mins % 60;
      h = h < 10 ? "0" + h : h; // (or alternatively) h = String(h).padStart(2, '0')
      m = m < 10 ? "0" + m : m; // (or alternatively) m = String(m).padStart(2, '0')
      return `${h}:${m}`;
    },
    changeView(e, event) {
      if (event.view === "week") {
        this.dayview = false;
        // (this.timeFrom = 8 * 60), (this.timeTo = 21 * 60);
        this.time_range = localStorage.getItem("time_range")
          ? JSON.parse(localStorage.getItem("time_range"))
          : [480, 1260];
        this.timeFrom = this.time_range[0];
        this.timeTo = this.time_range[1];
        this.timeStep = 60;
        this.selected_ot = localStorage.getItem("selected_ot")
          ? JSON.parse(localStorage.getItem("selected_ot"))
          : this.splitDays.map((data) => data.label);
        this.change_ot_selection();
        this.week_start_date = this.convertDate(event.startDate);
        this.week_end_date = this.convertDate(event.endDate);
        this.get_surgery_details(
          this.convertDate(event.startDate),
          this.convertDate(event.endDate)
        );
        this.surgery.slot_name = "";
      } else {
        this.dayview = true;
        if (
          this.convertDate(event.startDate) >
          this.convertDate(this.week_end_date) ||
          this.convertDate(event.startDate) <
          this.convertDate(this.week_start_date)
        ) {
          this.get_surgery_details(
            this.convertDate(event.startDate),
            this.convertDate(event.startDate)
          );
        } else {
          this.events = this.initial_events;
        }
      }
    },
    eventDragCreate(e, event) {
      // var start_time = new Date(event.start);
      // var current_time = new Date();
      // if (start_time < current_time) {
      //   this.$toast.error("Surgery Date-Time can not be in the past.", {
      //     position: "bottom-right",
      //     timeout: 5000,
      //     closeOnClick: true,
      //     pauseOnFocusLoss: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     draggablePercent: 0.6,
      //     showCloseButtonOnHover: false,
      //     hideProgressBar: true,
      //     closeButton: "button",
      //     icon: true,
      //     rtl: false,
      //   });
      //   this.deleteEventFunction();
      //   return;
      // }
      if (this.surgery.slot_name.length == 0) {
        this.$toast.error("Surgery creation is allowed only through Slot.", {
          position: "bottom-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.deleteEventFunction();
        return;
      }
      this.showEventCreationDialog = true;
      this.setSlotEvent(event);
      this.setSurgeryEvent(event);
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetValidation();
      }
    },
    setSurgeryEvent(event) {
      //  this.surgery.slot_name = event.title
      this.surgery.surgery_start_time = event.start.formatTime();
      //  this.surgery.surgery_end_time = event.end.formatTime()
      this.surgery.surgery_date = event.start.format("YYYY-MM-DD");
      this.surgery.ot = this.splitDays
        .filter((data) => data.id === event.split)
        .map((it) => it.label)
        .toString();
    },
    setSlotEvent(event) {
      this.slot.slot_duration = this.convertMinsToHrsMins(
        event.endTimeMinutes - event.startTimeMinutes
      );
      this.slot.slot_date = event.start.format("DD/MM/YYYY");
      this.slot.slot_start_time = event.start.formatTime();
      this.slot.slot_end_time = event.end.formatTime();
    },
    reset_sugery_slot() {
      (this.surgery.patient = {}),
        (this.surgery.procedure = {}),
        (this.surgery.special_request = ""),
        (this.surgery.surgery_end_time = "-"),
        (this.surgery.surgery_duration = "-");
    },
    checkDatesFallsBetween(
      drag_surgery_start_time,
      drag_surgery_end_time,
      drop_slot_start_time,
      drop_slot_end_time
    ) {
      if (
        drag_surgery_start_time !== null &&
        drag_surgery_end_time != null &&
        drop_slot_start_time !== null &&
        drop_slot_end_time != null
      ) {
        let surgery_start_time = new Date(
          drag_surgery_start_time.replace(" ", "T").toString().toLocaleString()
        ).getTime();
        let surgery_end_time = new Date(
          drag_surgery_end_time.replace(" ", "T").toString().toLocaleString()
        ).getTime();

        let slot_start_time = new Date(
          drop_slot_start_time.replace(" ", "T").toString().toLocaleString()
        ).getTime();
        let slot_end_time = new Date(
          drop_slot_end_time.replace(" ", "T").toString().toLocaleString()
        ).getTime();

        return this.isBetween(
          slot_start_time,
          slot_end_time,
          surgery_start_time,
          surgery_end_time
        );
      }
    },
    isBetween(num1, num2, value1, value2) {
      if (value1 > num1 && value1 < num2 && value2 > num1 && value2 < num2) {
        return true;
      } else {
        return false;
      }
    },
    // Get Get Surgeon list
    async get_surgeon_list() {
      await CommonApi.post_data("get_surgeon_list_0009").then((res) => {
        if (res.data.length && res.status == 200) {
          this.surgeon_items = res.data;
        }
      });
    },
    // Get Get Partner Surgeries
    async get_surgery_details(from_date, to_date, isWeekCall = false) {
      let splitDays = [];
      this.splitDays.map((data) => {
        splitDays.push(data.id);
      });
      let data = {
        rooms: this.selected_ot,
        from_date: from_date,
        to_date: to_date,
        surgeon: this.selected_surgeon_root
          ? this.selected_surgeon_root.name
          : "",
        procedure: this.selected_procedure_root
          ? this.selected_procedure_root.name
          : "",
      };
      // partner_id: "DOC011"
      await CommonApi.post_data("get_surgery_details_0007", data).then(
        (res) => {
          if (res.status == 200) {
            this.events = res.data;
            if (isWeekCall) {
              this.initial_events = this.events;
            }
          }
        }
      );
    },
    // Get Get Surgeon list
    async get_ot_list() {
      await CommonApi.post_data("get_ot_list_0025").then((res) => {
        if (res.data.length && res.status == 200) {
          this.splitDays = res.data;
        }
      });
    },
    // Get Get Patient list
    async get_patient_list(from_date, to_date) {
      let splitDays = [];
      this.splitDays.map((data) => {
        splitDays.push(data.id);
      });
      let data = {
        rooms: splitDays,
        from_date: from_date,
        to_date: to_date,
        list_view: false,
      };
      // partner_id: "DOC011",
      await CommonApi.post_data("get_patient_list_0008", data).then((res) => {
        if (res.data.length && res.status == 200) {
          this.patient_items = res.data;
        }
      });
    },

    // Get Get Surgeon list
    // async get_surgeon_list() {
    //   await CommonApi.post_data("get_surgeon_list_0009").then((res) => {
    //     if (res.data.length) {
    //       this.surgeon_items = res.data;
    //     }
    //   });
    // },

    // Get Get Procedure list
    async get_procedure_list(surgeon) {
      // let splitDays = [];
      // this.splitDays.map((data) => {
      //   splitDays.push(data.id);
      // });
      let data = {
        surgeon: surgeon,
      };
      // partner_id: "DOC011",
      await CommonApi.post_data("get_procedure_list_0010", data).then((res) => {
        if (res.data.length && res.status == 200) {
          this.procedure_items = res.data;
        }
      });
    },

    // Get Get Partner Surgeries
    async get_partner_surgeries(from_date, to_date) {
      let splitDays = [];
      this.splitDays.map((data) => {
        splitDays.push(data.id);
      });
      let data = {
        rooms: this.selected_ot,
        from_date: from_date,
        to_date: to_date,
        surgeon: this.selected_surgeon_root
          ? this.selected_surgeon_root.name
          : "",
        procedure: this.selected_procedure_root
          ? this.selected_procedure_root.name
          : "",
      };
      // partner_id: "DOC011",
      await CommonApi.post_data("get_partner_surgeries_0013", data).then(
        (res) => {
          if (res.status == 200) {
            this.events = res.message;
          } else {
            this.events = [];
          }
        }
      );
    },

    // Get Create New Surgery
    async create_surgery() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        let data = {
          slot: this.surgery.slot_name,
          ot: this.surgery.ot,
          patient: this.surgery.patient.name,
          procedure: this.surgery.procedure.name,
          surgeon: this.surgery.surgeon,
          date: this.surgery.surgery_date,
          start_time: this.surgery.surgery_start_time + ":00",
          special_request: this.surgery.special_request,
          laterlity: this.surgery.laterlity,
        };
        await CommonApi.post_data("create_surgery_0011", data).then((res) => {
          if (res && res.data && res.data.length && res.status == 200) {
            // console.log("printing from here");
            this.validate_payment(this.surgery.slot_name);
            this.$toast.success(res.message, {
              position: "bottom-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            // this.get_surgery_details(this.week_start_date, this.week_end_date);
            if (this.dayview == true) {
              this.get_partner_surgeries(
                this.week_start_date,
                this.week_end_date
              );
            } else {
              this.get_surgery_details(
                this.week_start_date,
                this.week_end_date
              );
            }
            this.cancelEventCreation();
            this.closeCreationDialog();
            this.reset_sugery_slot();
          } else {
            this.$toast.error(
              res.message ? res.message : "Something went wrong",
              {
                position: "bottom-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              }
            );
            // this.reset_sugery_slot()
          }
        });
      }
    },
    async validate_payment(id, dialog = true) {
      this.surgeryPaymentDetails = {};
      let data = {
        slot_id: id,
      };
      await CommonApi.post_data("validate_payment_0026", data).then((res) => {
        if (res && res.status == 200) {
          if (res.data.payment_required) {
            // console.log(dialog);
            if (dialog) this.surgeryPaymentDialog = true;
            this.surgeryPaymentDetails = res.data;
          }
        }
      });
    },
    // Get Procedure details
    async get_procedure_details() {
      if (this.surgery.surgeon && this.surgery.procedure.name) {
        let data = {
          surgeon: this.surgery.surgeon,
          procedure: this.surgery.procedure.name,
          start_time: this.surgery.surgery_start_time + ":00",
        };
        await CommonApi.post_data("get_procedure_details_0012", data).then(
          (res) => {
            if (res && res.data && res.status == 200) {
              this.surgery.surgery_end_time = res.data.end_time;
              this.surgery.surgery_duration = res.data.procedure_time;
              this.surgery.special_request = res.data.surgeon_special_request;
            }
          }
        );
      }
    },

    // Discard Surgery
    async discard_surgery(surgery) {
      Swal.fire({
        title: "Discard Surgery",
        text: `Are you really want to Discard surgery ${surgery} ?`,
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        cancelButtonColor: "#d33",
      }).then((result) => {
        if (result.value) {
          let data = {
            surgery: surgery,
          };
          CommonApi.post_data("cancel_surgery_0017", data).then((res) => {
            if (res && res.status == 200) {
              this.$toast.success(res.message, {
                position: "bottom-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
              this.get_surgery_details(
                this.week_start_date,
                this.week_end_date
              );
              this.showDialog = false;
            } else {
              this.$toast.error(res.message, {
                position: "bottom-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }
          });
        }
      });
    },

    // Get Surgery Movement Details
    async get_details_for_surgery_movement(slot, surgery, start_time) {
      let data = {
        slot: slot,
        surgery: surgery,
      };
      await CommonApi.post_data(
        "get_details_for_surgery_movement_0015",
        data
      ).then((res) => {
        if (res && res.data && res.status == 200) {
          this.get_procedure_details_copy(
            slot,
            surgery,
            res.data.surgeon,
            res.data.procedure,
            start_time
          );
        }
      });
    },

    // Get Procedure details Copy
    async get_procedure_details_copy(
      slot,
      surgery,
      surgeon,
      procedure,
      start_time
    ) {
      let data = {
        surgeon: surgeon,
        procedure: procedure,
        start_time: start_time,
      };
      await CommonApi.post_data("get_procedure_details_0012", data).then(
        (res) => {
          if (res && res.data && res.status == 200) {
            this.change_slot(
              slot,
              surgery,
              surgeon,
              start_time,
              res.data.end_time
            );
          }
        }
      );
    },

    // Change Slot
    async change_slot(slot, surgery, surgeon, start_time, end_time) {
      let data = {
        slot: slot,
        surgery: surgery,
        surgeon: surgeon,
        start_time: start_time + ":00",
        end_time: end_time,
      };
      await CommonApi.post_data("change_slot_0016", data).then((res) => {
        if (res && res.status == 200) {
          this.$toast.success(res.message, {
            position: "bottom-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        } else {
          this.$toast.error(res.message, {
            position: "bottom-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
        if (this.dayview == true) {
          this.get_partner_surgeries(this.week_start_date, this.week_end_date);
        } else {
          this.get_surgery_details(this.week_start_date, this.week_end_date);
        }
      });
    },
  },
  async mounted() {
    await this.get_ot_list();
    this.onResize();
    this.time_range = localStorage.getItem("time_range")
      ? JSON.parse(localStorage.getItem("time_range"))
      : [480, 1260];
    this.selected_surgeon_root = localStorage.getItem("selected_surgeon")
      ? JSON.parse(localStorage.getItem("selected_surgeon"))
      : "";
    this.timeFrom = this.time_range[0];
    this.timeTo = this.time_range[1];
    window.addEventListener("resize", this.onResize, { passive: true });
    this.all_ots = this.splitDays.map((data) => data.label);
    this.selected_ot = localStorage.getItem("selected_ot")
      ? JSON.parse(localStorage.getItem("selected_ot"))
      : [];
    this.change_ot_selection();
    const today = new Date();
    // Get the first day of the current week (Monday)
    const firstDay = new Date(
      today.setDate(
        today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)
      )
    );
    // Get the last day of the current week (Sunday)
    const lastDay = new Date(
      today.setDate(today.getDate() - today.getDay() + 7)
    );
    this.week_start_date = firstDay.toISOString().split("T")[0];
    this.week_end_date = lastDay.toISOString().split("T")[0];
    this.get_patient_list(
      firstDay.toISOString().split("T")[0],
      lastDay.toISOString().split("T")[0]
    );
    // this.get_surgeon_list();
    this.get_procedure_list(
      this.selected_surgeon_root ? this.selected_surgeon_root.name : ""
    );
    this.get_surgery_details(
      firstDay.toISOString().split("T")[0],
      lastDay.toISOString().split("T")[0],
      true
    );
    this.get_surgeon_list();
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
};
</script>
  
<style scoped lang="scss">
// General styles.
* {
  font-size: 12px;
  margin: 0;
  padding: 0;
}

html,
body,
#app {
  height: 100%;
}

#app {
  padding: 40px;
  box-sizing: border-box;
}

// a {
//   position: fixed;
//   top: 5px;
//   right: 5px;
//   color: #333;
// }

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}
</style>
    