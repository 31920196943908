<template>
  <v-container class="blue_font_container">
    <v-row>
      <v-col cols="12" sm="6" md="4" xl="4">
        <v-card>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h6 font-weight-bold"
                >Today Surgery Count</v-list-item-title
              >
              <v-list-item-subtitle class="text-h6">{{
                today_surgery_count
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" xl="4">
        <v-card>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h6 font-weight-bold"
                >Patient Count</v-list-item-title
              >
              <v-list-item-subtitle class="text-h6">{{
                patient_count
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" xl="4">
        <v-card>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h6 font-weight-bold"
                >Surgery Count</v-list-item-title
              >
              <v-list-item-subtitle class="text-h6">{{
                total_surgery_count
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6" md="4" xl="4">
        <v-card>
          <v-card-title class="text-h6 font-weight-bold"
            >Upcoming Surgery List</v-card-title
          >
          <div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      class="text-left"
                      v-for="(item, index) in upcoming_surgery_fields"
                      :key="index"
                    >
                      {{ item }}
                    </th>
                  </tr>
                </thead>
                <tbody v-if="upcoming_surgery_items.length">
                  <tr
                    v-for="(item, index) in upcoming_surgery_items"
                    :key="index"
                  >
                    <td>{{ formatDate(item.date) }}</td>
                    <td>{{ item.patient_name }}</td>
                    <td>{{ item.procedure }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <p
              class="no-data"
              v-if="!upcoming_surgery_items.length && !isApiCalling"
            >
              No data found
            </p>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" xl="4">
        <v-card>
          <v-card-title class="text-h6 font-weight-bold"
            >Completed Surgery List</v-card-title
          >
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-left"
                    v-for="(item, index) in completed_surgery_fields"
                    :key="index"
                  >
                    {{ item }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="completed_surgery_items.length">
                <tr
                  v-for="(item, index) in completed_surgery_items"
                  :key="index"
                >
                  <td>{{ formatDate(item.date) }}</td>
                  <td>{{ item.patient_name }}</td>
                  <td>{{ item.procedure }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <p
            class="no-data"
            v-if="!completed_surgery_items.length && !isApiCalling"
          >
            No data found
          </p>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" xl="4">
        <v-card>
          <v-card-title class="text-h6 font-weight-bold">Invoices</v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-left blue_font"
                    v-for="(item, index) in invoices_fields"
                    :key="index"
                  >
                    {{ item }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="invoices_items.length">
                <tr v-for="(item, index) in invoices_items" :key="index">
                  <td>{{ item.name }}</td>
                  <td>{{ item.status }}</td>
                  <td>{{ item.grand_total }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <p class="no-data" v-if="!invoices_items.length && !isApiCalling">
            No data found
          </p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
    
<script>
import CommonApi from "@/services/CommonApi";

export default {
  name: "DashBoard",
  data() {
    return {
      today_surgery_count: 0,
      patient_count: 0,
      total_surgery_count: 0,
      upcoming_surgery_fields: ["Date", "Patient", "Procedure"],
      completed_surgery_fields: ["Date", "Patient", "Procedure"],
      upcoming_surgery_items: [],
      completed_surgery_items: [],
      invoices_fields: ["Invoice Id", "Status", "Total"],
      invoices_items: [],
      isApiCalling: true,
    };
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    // Get Dashboard Details
    async get_dashboard_details() {
      await CommonApi.get_data("get_dashboard_details_0021").then((res) => {
        if (res && res.data) {
          this.today_surgery_count = res.data.today_surgery;
          this.patient_count = res.data.patient;
          this.total_surgery_count = res.data.total_surgery;
          this.upcoming_surgery_items = res.data.upcoming_surgery;
          this.completed_surgery_items = res.data.completed_surgery;
          this.invoices_items = res.data.invoices;
          this.isApiCalling = false;
        }
      });
    },
  },
  mounted() {
    this.get_dashboard_details();
  },
};
</script>