import Vue from "vue";
import VueRouter from "vue-router";
import Router from "vue-router";
/* isAuthenticated To Check User Is Authenticated */
import { isAuthenticated } from "./authenticate";

Vue.use(VueRouter);

import Body from "../components/body.vue";
import Dashboard from "../pages/dashboard.vue";
import SurgeryCalendar from "../pages/surgery_calendar.vue";
import SlotCalendar from "../pages/slot_calendar.vue";
// import SurgeryBooking from "../pages/surgery_booking.vue";
import SurgeryList from "../pages/surgery_list.vue";
import NewPatient from "../pages/new_patient.vue";
import UpdatePatient from "../pages/update_patient.vue";
import PatientList from "../pages/patient_list.vue";
import Transactions from "../pages/transactions.vue";
import ManageProfile from "../pages/manage_profile.vue";
import ManageAvailability from "../pages/manage_availability.vue";
import AvailabilityCalendar from "../pages/availability_calendar.vue";
import PaymentSuccess from "../pages/payment_success.vue";

Vue.use(Router);

const routes = [
  { path: "", beforeEnter: isAuthenticated, redirect: { name: "login" } },
  {
    path: "/login",
    name: "login",
    meta: {
      requiresAuth: true,
      title: "Login",
    },
    beforeEnter: async (to, from, next) => {
      let secret = localStorage.getItem("userInfo");
      if (secret == null) {
        localStorage.removeItem("userInfo");
        next();
      } else if (localStorage.getItem("userInfo") && to.path == "/login") {
        next("/dashboard");
      } else {
        return next(from);
      }
    },
    component: () => import("@/auth/login"),
  },
  {
    path: "/forgot_password",
    name: "forgot_password",
    meta: {
      requiresAuth: true,
      title: "Forgot Password",
    },
    component: () => import("@/auth/forgot_password"),
  },
  {
    path: "/",
    component: Body,
    meta: {
      requiresAuth: true,
      title: "Dashboard",
    },
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        beforeEnter: isAuthenticated,
        component: Dashboard,
      },
    ],
  },
  {
    path: "/",
    component: Body,
    meta: {
      requiresAuth: true,
      title: "Slot Calendar",
    },
    children: [
      {
        path: "/slot-calendar",
        name: "Slot Calendar",
        beforeEnter: isAuthenticated,
        component: SlotCalendar,
      },
    ],
  },
  {
    path: "/",
    component: Body,
    meta: {
      requiresAuth: true,
      title: "Surgery Calendar",
    },
    children: [
      {
        path: "/surgery-calendar",
        name: "Surgery Calendar",
        beforeEnter: isAuthenticated,
        component: SurgeryCalendar,
      },
    ],
  },
  // {
  //   path: "/",
  //   component: Body,
  //   meta: {
  //     requiresAuth: true,
  //     title: "New Surgery",
  //   },
  //   children: [
  //     {
  //       path: "/new-surgery",
  //       name: "New Surgery",
  //       beforeEnter: isAuthenticated,
  //       component: SurgeryBooking,
  //     },
  //   ],
  // },
  {
    path: "/",
    component: Body,
    meta: {
      requiresAuth: true,
      title: "Surgery List",
    },
    children: [
      {
        path: "/surgery-list",
        name: "Surgery List",
        beforeEnter: isAuthenticated,
        component: SurgeryList,
      },
    ],
  },
  {
    path: "/",
    component: Body,
    meta: {
      requiresAuth: true,
      title: "New Patient",
    },
    children: [
      {
        path: "/new-patient",
        name: "New Patient",
        beforeEnter: isAuthenticated,
        component: NewPatient,
      },
      {
        path: "/update-patient/:id",
        name: "Update Patient",
        beforeEnter: isAuthenticated,
        component: UpdatePatient,
      },
    ],
  },
  {
    path: "/",
    component: Body,
    meta: {
      requiresAuth: true,
      title: "Patient List",
    },
    children: [
      {
        path: "/patient-list",
        name: "Patient List",
        beforeEnter: isAuthenticated,
        component: PatientList,
      },
    ],
  },
  {
    path: "/",
    component: Body,
    meta: {
      requiresAuth: true,
      title: "Transactions",
    },
    children: [
      {
        path: "/transactions",
        name: "Transactions",
        beforeEnter: isAuthenticated,
        component: Transactions,
      },
    ],
  },
  {
    path: "/",
    component: Body,
    meta: {
      requiresAuth: true,
      title: "Manage Profile",
    },
    children: [
      {
        path: "/manage-profile",
        name: "Manage Profile",
        beforeEnter: isAuthenticated,
        component: ManageProfile,
      },
    ],
  },
  {
    path: "/",
    component: Body,
    meta: {
      requiresAuth: true,
      title: "Availability",
    },
    children: [
      {
        path: "/create-availability",
        name: "Manage Availability",
        beforeEnter: isAuthenticated,
        component: ManageAvailability,
      },
      {
        path: "/availability-calendar",
        name: "Availability Calendar",
        beforeEnter: isAuthenticated,
        component: AvailabilityCalendar,
      },
    ],
  },
  {
    path: "/",
    component: Body,
    meta: {
      requiresAuth: true,
      title: "Payment Success",
    },
    children: [
      {
        path: "/payment_success",
        name: "Payment Success",
        beforeEnter: isAuthenticated,
        component: PaymentSuccess,
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    beforeEnter: isAuthenticated,
    redirect: { name: "Dashboard" },
  },
];

const router = new Router({
  routes,
  // base: process.env.BASE_URL,
  base: "/",
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;

// ...

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

// ...
