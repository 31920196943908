<template>
  <!-- <v-container class="sugery_list">
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-card-title>Surgery List</v-card-title>
          <div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" v-for="(item, index) in surgery_fields" :key="index">
                      {{ item }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in surgery_items" :key="index">
                    <td>{{ item.patient }}</td>
                    <td>{{ item.health_center }}</td>
                    <td>{{ item.slot }}</td>
                    <td>{{ item.operating_theatre }}</td>
                    <td>{{ item.procedure }}</td>
                    <td>{{ item.date }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <p class="no-data" v-if="!surgery_items.length && !isApiCalling">No data found</p>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container> -->
  <div>
    <v-app id="inspire">
      <div style="margin-top: 20px; margin-left: 35px; margin-right: 35px" class="slot_calendar">
        <v-row>
          <v-col>
            <v-autocomplete solo item-value="name" item-text="doctor_name" return-object required
              v-model="selected_surgeon" :items="surgeon_items" label="Select Surgeon" outlined clearable dense>
              <template slot="selection" slot-scope="data">
                <!-- HTML that describe how select should render selected items -->
                <small>{{ data.item.doctor_name }} - {{ data.item.name }}</small>
              </template>
              <template slot="item" slot-scope="data">
                <!-- HTML that describe how select should render items when the select is open -->
                <small>{{ data.item.doctor_name }} - {{ data.item.name }}</small>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-menu v-model="from_date_menu" :close-on-content-click="false" :nudge-right="40"
              transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="computed_from_date" label="From Date" prepend-inner-icon="mdi-calendar" readonly
                  outlined dense v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="from_date" @input="from_date_menu = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-menu v-model="to_date_menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
              offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="computed_to_date" label="To Date" prepend-inner-icon="mdi-calendar" readonly
                  outlined dense v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="to_date" @input="to_date_menu = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-btn :disabled="to_date === null || from_date === null" class="ml-2 mr-2 secondary"
              @click="get_partner_surgeries()">Submit</v-btn>
            <!-- <v-btn :disabled="
              to_date === null ||
              from_date === null" @click="get_ledger(true)">Download</v-btn> -->
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" v-for="(item, index) in surgery_fields" :key="index">
                        {{ item }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in surgery_items" :key="index">
                      <td>{{ item.split }}</td>
                      <td>{{ item.title }}</td>
                      <td>{{ formatDate(item.date) }}</td>
                      <td>{{ item.slot }}</td>
                      <td>{{ item.patient_name }}</td>
                      <td>{{ item.procedure }}</td>
                      <td>{{ item.surgeon_name }}</td>
                      <td>{{ item.procedure_time }}</td>
                      <td>{{ formatTime(item.start) }}</td>
                      <td>{{ formatTime(item.end) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <p class="no-data" v-if="!surgery_items.length && !isApiCalling">
                No data found
              </p>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-app>
  </div>
</template>
      
<script>
import CommonApi from "@/services/CommonApi";

export default {
  name: "SurgeryList",
  data() {
    return {
      surgery_fields: [
        "OT",
        "Surgery",
        "Date",
        "Slot",
        "Patient Name",
        "Procedure",
        "Surgeon Name",
        "Procedure Time",
        "Start",
        "End",
      ],
      surgery_items: [],
      isApiCalling: true,
      from_date_menu: false,
      to_date_menu: false,
      from_date: "",
      to_date: "",
      splitDays: ["OT 1", "OT 2", "TEST OT"],
      validate_surgeon: {
        select1: [
          (v) => Boolean(Object.keys(v || {})[0]) || "Field is required",
        ],
      },
      surgeon_items: [],
      selected_surgeon: null,
    };
  },
  computed: {
    computed_from_date() {
      return this.formatDate(this.from_date);
    },
    computed_to_date() {
      return this.formatDate(this.to_date);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    formatTime(date) {
      if (!date) return "";
      // const [date_string, time_string] = date.split(" ");
      return date.split(" ")[1];
    },
    // Get Get Surgeon list
    async get_surgeon_list() {
      await CommonApi.post_data("get_surgeon_list_0009").then((res) => {
        if (res.data.length && res.status == 200) {
          this.surgeon_items = res.data;
        } else {
          this.surgeon_items = [];
        }
      });
    },
    convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    },
    async get_partner_surgeries() {
      let data = {
        rooms: this.splitDays,
        from_date: this.convert(this.from_date),
        to_date: this.convert(this.to_date),
        surgeon: this.selected_surgeon ? this.selected_surgeon.name : "",
        procedure: "",
      };
      // partner_id: "DOC011"
      await CommonApi.post_data("get_partner_surgeries_0013", data).then(
        (res) => {
          if (res.message.length && res.status == 200) {
            this.surgery_items = res.message;
            this.isApiCalling = false;
          } else {
            this.surgery_items = [];
          }
        }
      );
    },
  },
  mounted() {
    this.selected_surgeon = localStorage.getItem("selected_surgeon")
      ? JSON.parse(localStorage.getItem("selected_surgeon"))
      : "";
    let date = new Date();
    this.to_date = date.toISOString().split("T")[0];
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    this.from_date = this.convert(firstDay);
    this.get_partner_surgeries();
    this.get_surgeon_list();
  },
  watch: {
    selected_surgeon(value) {
      localStorage.setItem("selected_surgeon", JSON.stringify(value));
    },
  },
};
</script>