<template>
  <v-container fluid class="success_page_container blue_font_container">
    <v-row justify="center" align="center" class="ma-5">
      <v-col cols="12" sm="8" md="6">
        <v-card class="pa-10 text-center success-card" color="primary" dark>
          <v-icon class="success-icon" color="white"
            >mdi-check-circle-outline</v-icon
          >
          <h1 class="success-title">Payment Successful!</h1>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
    
<script>
export default {
  name: "PaymentSuccess",
  mounted() {
    setTimeout(() => {
      this.redirect();
    }, 5000);
  },
  methods: {
    redirect() {
      this.$router.push("/dashboard");
    },
  },
};
</script>

